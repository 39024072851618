import theme from "./theme";
import React from "react";
import "./App.css";
import ResponsiveAppBar from "./components/Responsive-App-Bar";
import { ThemeProvider } from "@emotion/react";
import BasicCard from "./components/BasicCard";

const REMOTE_HOST = "https://api.tikory.com/rankings";

function App() {
  const [rankingList, setRankingList] = React.useState(null);
  React.useEffect(() => {
    const getData = async () => {
      const response = await fetch(REMOTE_HOST);
      const data = await response.json();
      console.log(data);
      setRankingList(data);
    };
    getData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ResponsiveAppBar />
        <h1 style={{ fontFamily: "Montserrat", color: "white", marginTop: 30 }}>
          Top Charts TikTok Songs Today
        </h1>
        <p style={{ fontFamily: "Montserrat", color: "#CAC5D6" }}>
          {rankingList &&
            "Updated " +
              rankingList["dates_queried"][
                rankingList["dates_queried"].length - 1
              ] +
              " 12:00 AM PST"}
        </p>
        <div style={{ marginTop: 10, display: "block" }}>
          {rankingList &&
            rankingList["data"].map((item, index) => (
              <BasicCard sound={item} rank={index} />
            ))}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
