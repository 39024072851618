import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Rank = (props) => {
  const { data, rank, toggle } = props;

  return (
    <div style={{ display: "flex" }}>
      <h1 style={{ color: "white" }}>{rank + 1}</h1>
      <img
        src={data["coverMedium"]}
        style={{ marginLeft: 50 }}
        height="58"
        width="58"
        onClick={toggle}
      />
    </div>
  );
};

const TitleArtist = (props) => {
  const { data } = props;
  return (
    <div style={{ textAlign: "left", width: 200 }}>
      <p style={{ color: "#EAEAEA", fontSize: 15 }}>
        <b>{data["title"]}</b>
      </p>
      <p style={{ color: "#D3D3D3", fontSize: 10 }}>{data["authorName"]}</p>
    </div>
  );
};

const Duration = (props) => {
  const { data } = props;
  console.log(data);
  return (
    <div style={{ display: "flex", marginRight: "9%" }}>
      <p style={{ color: "#EAEAEA", fontSize: 15 }}>
        <b>{data["duration"]}</b>
      </p>
      <p style={{ color: "#D3D3D3", fontSize: 15, marginLeft: 3 }}>secs</p>
    </div>
  );
};

const Ratings = (props) => {
  const { data } = props;
  const options = ["Very Popular", "Use It Now! ", "On the Rise  "];
  let i = Math.floor(Math.random() * 3);
  return (
    <p style={{ color: "#FFD64C", marginRight: "10%" }}>
      <b>{options[i]}</b>
    </p>
  );
};

const Daily = (props) => {
  const { data } = props;
  let d = data["frequency_of_appearance"];
  let df = data["change_in_frequency"];
  // rise
  let old = d - df;
  let pf = Math.round((df / old) * 100);

  if (pf >= 0) {
    return (
      <div style={{ display: "flex" }}>
        <p style={{ color: "#5CDE2D", fontSize: 15 }}>
          <b>{pf}%</b>
        </p>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <p style={{ color: "#F00", fontSize: 15 }}>
        <b>{-1 * pf}%</b>
      </p>
    </div>
  );
};

export default function BasicCard(props) {
  const { sound, rank } = props;
  const metadata = JSON.parse(sound["metadata"][0]);

  const [audio] = React.useState(
    new Audio(metadata["music"] ? metadata["music"]["playUrl"] : "")
  );
  const [playing, setPlaying] = React.useState(false);

  const toggle = () => setPlaying(!playing);

  React.useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  React.useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  if (!metadata["music"]) {
    return <div></div>;
  }

  const redirect = () => {
    let id = metadata["music"]["id"];
    window.location.href = "https://www.tiktok.com/music/deeznuts-" + id;
  };

  return (
    <React.Fragment>
      <Box sx={{ minWidth: 275, maxHeight: 50, margin: 5, marginX: 10 }}>
        <Card variant="outlined">
          <CardContent
            style={{
              display: "flex",
              backgroundColor: "#191E28",
            }}
          >
            <div style={{ marginRight: "9%", marginLeft: "2%" }}>
              <Rank data={metadata["music"]} rank={rank} toggle={toggle} />
            </div>
            <div style={{ marginRight: "9%" }}>
              <TitleArtist data={metadata["music"]} />
            </div>
            <Duration data={metadata["music"]} />
            <Ratings />
            <div style={{ marginRight: "9%" }}>
              <Daily data={sound} />
            </div>

            <Button
              sx={{
                marginX: 10,
                my: 2,
                color: "white",
                display: "block",
              }}
              style={{
                backgroundColor: "#E52650",
                fontFamily: "Helvetica Neue",
              }}
              onClick={redirect}
            >
              Use Sound
            </Button>
          </CardContent>
        </Card>
      </Box>
      <br></br>
      <br></br>
    </React.Fragment>
  );
}
